<template>
  <div class="project-operation-monitor  flex-grow-1 d-flex flex-column">
    <Backup class="align-self-start"></Backup>
    <SectionFrame class="flex-grow-1">
      <template>
        <SwitchTitle style="margin-bottom:1rem"
         :titleList="titleList" @selectTitle="selectTitleListener"></SwitchTitle>
        <template v-if="selectTitle">
          <RoomPrimary :primaryType="0"
           :projectInfo="selectRoom" v-if="selectTitle.value===0"></RoomPrimary>
          <AuxiliaryMonitor v-else></AuxiliaryMonitor>
        </template>
      </template>
    </SectionFrame>
  </div>
</template>
<script>

import RoomPrimary from '@/components/svgs/room-primary.vue';
import AuxiliaryMonitor from '@/components/project-operation-monitor/auxiliary-monitor.vue';
import ProjectMixin from '@/mixins/project-mixin';

export default {
  name: 'project-operation-monitor',
  mixins: [ProjectMixin],
  components: {
    RoomPrimary,
    AuxiliaryMonitor,
  },
  data() {
    return {
      titleList: [{
        title: '一次接线图',
        value: 0,
      }, {
        title: '辅助监控',
        value: 1,
      }],
      selectTitle: null,
      selectRoom: {
        IdStr: 'NjYwMjA0OTk5NkQzQzM4NA',
        ModelName: '银座好望角',
        oType: 'pds',
      },
    };
  },
  methods: {
    selectTitleListener(titleInfo) {
      this.selectTitle = titleInfo;
    },
  },
};
</script>
