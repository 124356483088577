import * as BABYLON from 'babylonjs';
import innerCubMaterial from '@/assets/images/three-dismensional/inner-cub.jpg';
import outterCubMaterial from '@/assets/images/three-dismensional/outter-cub.jpg';
import transCubMaterial from '@/assets/images/three-dismensional/trans-cub.jpg';
import ringCubMaterial from '@/assets/images/three-dismensional/ring-cub.png';
import Wall from '@/assets/images/three-dismensional/wall.jpg';
import GroundMa from '@/assets/images/three-dismensional/ground.jpg';

export default class Td1 {
  static createScene(canvas, engine = new BABYLON.Engine(canvas, true)) {
    // create the scene space
    const scene = new BABYLON.Scene(engine);
    scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);
    const camero = new BABYLON.ArcRotateCamera('Camera', -Math.PI / 3, Math.PI / 3, 100, BABYLON.Vector3.Zero(), scene);
    camero.attachControl(canvas, true);
    // all lights to the scene
    const light1 = new BABYLON.HemisphericLight('light1', new BABYLON.Vector3(0, 160, 0), scene);
    light1.intensity = 0.7;
    const light2 = new BABYLON.PointLight('light2', new BABYLON.Vector3(0, 100, 10), scene);
    light2.intensity = 0.1;
    const wallM = new BABYLON.StandardMaterial('wall', scene);
    wallM.diffuseTexture = new BABYLON.Texture(Wall, scene);
    wallM.diffuseTexture.hasAlpha = false;
    wallM.backFaceCulling = false;
    const wall0 = new BABYLON.MeshBuilder.CreatePlane('wall0', {
      width: 60,
      height: 10,
      frontUVs: new BABYLON.Vector4(0, 0.1, 0.6, 0),
      backUVs: new BABYLON.Vector4(0.2, 0.3, 0.4, 1),
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
      sourcePlane: new BABYLON.Plane(0, 0, 1, 30),
    }, scene);
    wall0.material = wallM;
    const wall1 = new BABYLON.MeshBuilder.CreatePlane('wall1', {
      width: 60,
      height: 10,
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
      sourcePlane: new BABYLON.Plane(0, 0, 1, -30),
    }, scene);
    wall1.material = wallM;
    const wall2 = new BABYLON.MeshBuilder.CreatePlane('wall2', {
      width: 60,
      height: 10,
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
      sourcePlane: new BABYLON.Plane(1, 0, 0, -30),
    }, scene);
    wall2.material = wallM;
    const wall3 = new BABYLON.MeshBuilder.CreatePlane('wall3', {
      width: 60,
      height: 10,
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
      sourcePlane: new BABYLON.Plane(1, 0, 0, 30),
    }, scene);
    wall3.material = wallM;
    const groundM = new BABYLON.StandardMaterial('groundM', scene);
    groundM.diffuseTexture = new BABYLON.Texture(GroundMa, scene);
    groundM.diffuseTexture.hasAlpha = false;
    const ground = new BABYLON.MeshBuilder.CreatePlane('ground', {
      width: 60,
      height: 60,
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
      sourcePlane: new BABYLON.Plane(0, 10, 0, 0.5),
    }, scene);
    ground.material = groundM;
    // 变压器材质
    const transMaterial = new BABYLON.StandardMaterial('box', scene);
    transMaterial.diffuseTexture = new BABYLON.Texture(transCubMaterial, scene);
    transMaterial.diffuseTexture.hasAlpha = false;
    transMaterial.backFaceCulling = false;
    // 进线箱材质
    const innerMaterial = new BABYLON.StandardMaterial('box', scene);
    innerMaterial.diffuseTexture = new BABYLON.Texture(innerCubMaterial, scene);
    innerMaterial.diffuseTexture.hasAlpha = false;
    innerMaterial.backFaceCulling = false;
    // 出线箱材质
    const outterMaterial = new BABYLON.StandardMaterial('box', scene);
    outterMaterial.diffuseTexture = new BABYLON.Texture(outterCubMaterial, scene);
    outterMaterial.diffuseTexture.hasAlpha = false;
    outterMaterial.backFaceCulling = false;
    // 环网柜材质
    const ringMaterial = new BABYLON.StandardMaterial('box', scene);
    ringMaterial.diffuseTexture = new BABYLON.Texture(ringCubMaterial, scene);
    ringMaterial.diffuseTexture.hasAlpha = false;
    ringMaterial.backFaceCulling = false;
    // faceUV
    const columns = 6; // 6 columns
    const rows = 1; // 1 row

    // alien sprite
    const faceUV = new Array(6);

    // set all faces to same
    for (let i = 0; i < 6; i += 1) {
      faceUV[i] = new BABYLON.Vector4(i / columns, 0, (i + 1) / columns, 1 / rows);
    }
    // const box0 = new BABYLON.MeshBuilder.CreateBox('box0', {
    //   height: 8,
    //   width: 4,
    //   depth: 4,
    //   faceUV,
    // }, scene);
    // box0.material = outterMaterial;
    // box0.position = new BABYLON.Vector3(-13, -1, -5);

    // const box0 = new BABYLON.MeshBuilder.CreateBox('box0', {
    //   height: 8,
    //   width: 4,
    //   depth: 4,
    //   faceUV,
    // }, scene);
    // box0.material = outterMaterial;
    // box0.position = new BABYLON.Vector3(-13.9, -1, -4);

    // 最前排 9台高压柜
    const box1 = new BABYLON.MeshBuilder.CreateBox('box1', {
      height: 8,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box1.material = outterMaterial;
    box1.position = new BABYLON.Vector3(-13.9, -1, -12);
    const box2 = new BABYLON.MeshBuilder.CreateBox('box2', {
      height: 8,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box2.material = outterMaterial;
    box2.position = new BABYLON.Vector3(-9.8, -1, -12);
    const box3 = new BABYLON.MeshBuilder.CreateBox('box3', {
      height: 8,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box3.material = outterMaterial;
    box3.position = new BABYLON.Vector3(-5.7, -1, -12);
    const box4 = new BABYLON.MeshBuilder.CreateBox('box4', {
      height: 8,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box4.material = outterMaterial;
    box4.position = new BABYLON.Vector3(-1.6, -1, -12);
    const box5 = new BABYLON.MeshBuilder.CreateBox('box5', {
      height: 8,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box5.material = outterMaterial;
    box5.position = new BABYLON.Vector3(2.5, -1, -12);
    const box6 = new BABYLON.MeshBuilder.CreateBox('box6', {
      height: 8,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box6.material = outterMaterial;
    box6.position = new BABYLON.Vector3(6.6, -1, -12);
    const box7 = new BABYLON.MeshBuilder.CreateBox('box7', {
      height: 8,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box7.material = outterMaterial;
    box7.position = new BABYLON.Vector3(10.7, -1, -12);
    const box8 = new BABYLON.MeshBuilder.CreateBox('box8', {
      height: 8,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box8.material = outterMaterial;
    box8.position = new BABYLON.Vector3(14.8, -1, -12);
    const box9 = new BABYLON.MeshBuilder.CreateBox('box9', {
      height: 8,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box9.material = outterMaterial;
    box9.position = new BABYLON.Vector3(18.9, -1, -12);

    // 中间 最右侧1台变压器 往左9台低压柜
    const box10 = new BABYLON.MeshBuilder.CreateBox('box10', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box10.material = outterMaterial;
    box10.position = new BABYLON.Vector3(-13.9, -1, 5);
    const box11 = new BABYLON.MeshBuilder.CreateBox('box11', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box11.material = outterMaterial;
    box11.position = new BABYLON.Vector3(-9.8, -1, 5);
    const box12 = new BABYLON.MeshBuilder.CreateBox('box12', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box12.material = outterMaterial;
    box12.position = new BABYLON.Vector3(-5.7, -1, 5);
    const box13 = new BABYLON.MeshBuilder.CreateBox('box13', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box13.material = outterMaterial;
    box13.position = new BABYLON.Vector3(-1.6, -1, 5);
    const box14 = new BABYLON.MeshBuilder.CreateBox('box14', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box14.material = outterMaterial;
    box14.position = new BABYLON.Vector3(2.5, -1, 5);
    const box15 = new BABYLON.MeshBuilder.CreateBox('box15', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box15.material = outterMaterial;
    box15.position = new BABYLON.Vector3(6.6, -1, 5);
    const box16 = new BABYLON.MeshBuilder.CreateBox('box16', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box16.material = outterMaterial;
    box16.position = new BABYLON.Vector3(10.7, -1, 5);
    const box17 = new BABYLON.MeshBuilder.CreateBox('box17', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box17.material = outterMaterial;
    box17.position = new BABYLON.Vector3(14.8, -1, 5);
    const box18 = new BABYLON.MeshBuilder.CreateBox('box18', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box18.material = outterMaterial;
    box18.position = new BABYLON.Vector3(18.9, -1, 5);
    // 变压器
    const box19 = new BABYLON.MeshBuilder.CreateBox('box19', {
      height: 8,
      width: 8,
      depth: 6,
      faceUV,
    }, scene);
    box19.material = transMaterial;
    box19.position = new BABYLON.Vector3(23, -1, 5);

    // 最后一排 最右侧1台变压器 左边7台低压柜
    const box20 = new BABYLON.MeshBuilder.CreateBox('box20', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box20.material = outterMaterial;
    box20.position = new BABYLON.Vector3(-5.7, -1, 20);
    const box21 = new BABYLON.MeshBuilder.CreateBox('box21', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box21.material = outterMaterial;
    box21.position = new BABYLON.Vector3(-1.6, -1, 20);
    const box22 = new BABYLON.MeshBuilder.CreateBox('box22', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box22.material = outterMaterial;
    box22.position = new BABYLON.Vector3(2.5, -1, 20);
    const box23 = new BABYLON.MeshBuilder.CreateBox('box23', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box23.material = outterMaterial;
    box23.position = new BABYLON.Vector3(6.6, -1, 20);
    const box24 = new BABYLON.MeshBuilder.CreateBox('box24', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box24.material = outterMaterial;
    box24.position = new BABYLON.Vector3(10.7, -1, 20);
    const box25 = new BABYLON.MeshBuilder.CreateBox('box25', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box25.material = outterMaterial;
    box25.position = new BABYLON.Vector3(14.8, -1, 20);
    const box26 = new BABYLON.MeshBuilder.CreateBox('box26', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box26.material = outterMaterial;
    box26.position = new BABYLON.Vector3(18.9, -1, 20);
    // 变压器
    const box27 = new BABYLON.MeshBuilder.CreateBox('box27', {
      height: 8,
      width: 8,
      depth: 6,
      faceUV,
    }, scene);
    box27.material = transMaterial;
    box27.position = new BABYLON.Vector3(23, -1, 20);

    return scene;
  }
}
