<template>
  <canvas id="renderCanvas" ref="renderCanvas" class="switching-room"></canvas>
</template>
<script>
import * as BABYLON from 'babylonjs';
import td1 from '@/components/three-dismensional/scripts/td1';
import td2 from '@/components/three-dismensional/scripts/td2';

export default {
  name: 'switching-room',
  props: ['selectOrder'],
  data() {
    return {
      selectName: null,
      redenerRoom: null,
      roomName: '',
      engine: null,
      scene: null,
    };
  },
  mounted() {
    this.renderDom = this.$refs.renderCanvas;
    this.roomName = this.$route.params.roomName;
    this.renderDiv(this.renderDom, this.roomName);
  },
  beforeRouteLeave(to, from, next) {
    this.stopEngineScene();
    next();
  },
  watch: {
    $route() {
      this.roomName = this.$route.params.roomName;
      this.renderDiv(this.renderDom, this.roomName);
    },
    selectOrder(val) {
      this.selectName = val;
      this.renderDiv(this.renderDom, this.roomName);
    },
  },
  methods: {
    stopEngineScene() {
      this.scene.dispose();
      this.engine.dispose();
    },
    renderDiv(element, roomName) {
      if (!this.engine) {
        this.engine = new BABYLON.Engine(element, true);
      }
      this.scene = this.getTdClass(roomName).createScene(element, this.engine);
      this.engine.runRenderLoop(() => {
        this.scene.render();
        this.engine.resize();
      });

      window.addEventListener('resize', () => {
        this.engine.resize();
      });
    },
    getTdClass(type) {
      return this.getNoClass(parseInt(type, 10) - 1);
    },
    getNoClass() {
      console.log('abc', this.selectName);
      if (this.selectName === 'Q0E2NDJBRDUwNzZGQ0JDRg') {
        return td2;
      }
      return td1;
    },
  },
};
</script>
<style scoped>
*:focus {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
}
</style>
