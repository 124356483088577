<template>
  <el-row
    class="none-height flex-grow-1 justify-content-stretch"
    type="flex"
    align="center"
  >
    <el-col :span="14" class="flex-grow-1 d-flex justify-content-center">
      <div
        class="
          select-option-container
          position-absolute
          d-flex
          justify-content-center
        "
      >
        <SelectOptionsNav
          @selectOption="getSelectOption"
          :selectOption="selectOrder"
          :selectList="selectOptionList"
        ></SelectOptionsNav>
      </div>
      <Room class="flex-grow-1" :selectOrder="selectOrder"></Room>
    </el-col>
    <el-col :span="10" class="d-flex flex-column flex-grow-1" style="background: transparent;">
      <el-table class="flex-grow-1 dark" size="small" style="width:93%;
      border:2px solid #1B71CC;max-height:83%; text-align:center; position: relative;
   top:8%;color:#fff"
   stripe
      :data="envirInfo"
      height="100%"
      :row-class-name="rowClassName">
      <!-- <el-table size="mini" stripe
            :data="envirInfo"
            height="100%"
            class="dark flex-grow-1 "
              :row-class-name="
            (...nu) => {
              return  'dark text-white';}
          "> -->
        <!-- <el-table-column label="编号"></el-table-column>
        <el-table-column label="名称"></el-table-column>
        <el-table-column label="放电频率(次)"></el-table-column>
        <el-table-column label="放电量低值(pC)"></el-table-column>
        <el-table-column label="平均放电(pC)"></el-table-column> -->
        <el-table-column label="编号" align="center" prop="id"></el-table-column>
        <el-table-column label="设备名称" align="center" prop="EquipmentName"></el-table-column>
        <el-table-column label="属性名称" align="center" prop="PropertyName"></el-table-column>
        <el-table-column label="值" align="center" prop="Value"></el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>
<script>
import Room from '@/components/three-dismensional/room.vue';
// import HumitureItem from '@/components/project-operation-monitor/humiture-item.vue';
import SelectOptionsNav from '@/components/general/select-options-nav.vue';
import { getSwitchRoomEnvir } from '@/api/switchroom';

export default {
  name: 'auxiliary-monitor',
  components: {
    Room,
    // HumitureItem,
    SelectOptionsNav,
  },
  data() {
    return {
      selectOrder: 0,
      selectOptionList: [],
      envirInfo: [],
    };
  },
  created() {
  },
  mounted() {
    this.selectOptionList = this.selectProject.Children;
    // this.selectOrder = this.selectProject.Children.IdStr;
    this.selectOrder = this.selectOptionList.length > 0 ? this.selectOptionList[0].IdStr : null;
    console.log(111111);
    console.log(this.selectOptionList);
    console.log(this.selectOrder);
    // this.form.id = this.selectProject.IdStr;
    // this.$emit('selectItem', this.form);
    // this.requestSwitchRoomEnvir();
  },
  computed: {
    selectProject() {
      return this.$store.getters.selectProject;
    },
    accountId() {
      return this.$store.getters.accountId;
    },
  },
  methods: {
    rowClassName({ row, rowIndex }) {
      // 把每一行的索引放进row.id
      // eslint-disable-next-line no-param-reassign
      row.id = rowIndex + 1;
    },
    // 获取选择
    getSelectOption(order) {
      this.selectOrder = order;
      console.log('22222');
      console.log(this.selectOrder);
      this.requestSwitchRoomEnvir();
    },
    // 请求配电室环境
    requestSwitchRoomEnvir() {
      getSwitchRoomEnvir({
        UIDstr: this.accountId,
        TaskIDstr: this.selectOrder,
      }).then((res) => {
        this.envirInfo = res;
        console.log(this.envirInfo);
        console.log('000');
        console.log('this.envirInfo', this.envirInfo);
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-table.dark th{
   color: #8696BF;
}
// 辅助监控表格
/deep/.el-table th, .el-table tr{
  background-color: transparent!important;
}
/deep/.el-table__row td{
background-color: #151A59;
}
/deep/.el-table__row tr{
  background: transparent;
}
/deep/.el-table--striped .el-table__body tr.el-table__row--striped td{
  background: transparent;
}
</style>
