import * as BABYLON from 'babylonjs';
import innerCubMaterial from '@/assets/images/three-dismensional/inner-cub.jpg';
import outterCubMaterial from '@/assets/images/three-dismensional/outter-cub.jpg';
import transCubMaterial from '@/assets/images/three-dismensional/trans-cub.jpg';
import ringCubMaterial from '@/assets/images/three-dismensional/ring-cub.png';
import Wall from '@/assets/images/three-dismensional/wall.jpg';
import GroundMa from '@/assets/images/three-dismensional/ground.jpg';

export default class Td1 {
  static createScene(canvas, engine = new BABYLON.Engine(canvas, true)) {
    // create the scene space
    const scene = new BABYLON.Scene(engine);
    scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);
    const camero = new BABYLON.ArcRotateCamera('Camera', -Math.PI / 3, Math.PI / 3, 100, BABYLON.Vector3.Zero(), scene);
    camero.attachControl(canvas, true);
    // all lights to the scene
    const light1 = new BABYLON.HemisphericLight('light1', new BABYLON.Vector3(0, 160, 0), scene);
    light1.intensity = 0.7;
    const light2 = new BABYLON.PointLight('light2', new BABYLON.Vector3(0, 100, 10), scene);
    light2.intensity = 0.1;
    const wallM = new BABYLON.StandardMaterial('wall', scene);
    wallM.diffuseTexture = new BABYLON.Texture(Wall, scene);
    wallM.diffuseTexture.hasAlpha = false;
    wallM.backFaceCulling = false;
    const wall0 = new BABYLON.MeshBuilder.CreatePlane('wall0', {
      width: 60,
      height: 10,
      frontUVs: new BABYLON.Vector4(0, 0.1, 0.6, 0),
      backUVs: new BABYLON.Vector4(0.2, 0.3, 0.4, 1),
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
      sourcePlane: new BABYLON.Plane(0, 0, 1, 30),
    }, scene);
    wall0.material = wallM;
    const wall1 = new BABYLON.MeshBuilder.CreatePlane('wall1', {
      width: 60,
      height: 10,
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
      sourcePlane: new BABYLON.Plane(0, 0, 1, -30),
    }, scene);
    wall1.material = wallM;
    const wall2 = new BABYLON.MeshBuilder.CreatePlane('wall2', {
      width: 60,
      height: 10,
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
      sourcePlane: new BABYLON.Plane(1, 0, 0, -30),
    }, scene);
    wall2.material = wallM;
    const wall3 = new BABYLON.MeshBuilder.CreatePlane('wall3', {
      width: 60,
      height: 10,
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
      sourcePlane: new BABYLON.Plane(1, 0, 0, 30),
    }, scene);
    wall3.material = wallM;
    const groundM = new BABYLON.StandardMaterial('groundM', scene);
    groundM.diffuseTexture = new BABYLON.Texture(GroundMa, scene);
    groundM.diffuseTexture.hasAlpha = false;
    const ground = new BABYLON.MeshBuilder.CreatePlane('ground', {
      width: 60,
      height: 60,
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
      sourcePlane: new BABYLON.Plane(0, 10, 0, 0.5),
    }, scene);
    ground.material = groundM;
    // 变压器材质
    const transMaterial = new BABYLON.StandardMaterial('box', scene);
    transMaterial.diffuseTexture = new BABYLON.Texture(transCubMaterial, scene);
    transMaterial.diffuseTexture.hasAlpha = false;
    transMaterial.backFaceCulling = false;
    // 进线箱材质
    const innerMaterial = new BABYLON.StandardMaterial('box', scene);
    innerMaterial.diffuseTexture = new BABYLON.Texture(innerCubMaterial, scene);
    innerMaterial.diffuseTexture.hasAlpha = false;
    innerMaterial.backFaceCulling = false;
    // 出线箱材质
    const outterMaterial = new BABYLON.StandardMaterial('box', scene);
    outterMaterial.diffuseTexture = new BABYLON.Texture(outterCubMaterial, scene);
    outterMaterial.diffuseTexture.hasAlpha = false;
    outterMaterial.backFaceCulling = false;
    // 环网柜材质
    const ringMaterial = new BABYLON.StandardMaterial('box', scene);
    ringMaterial.diffuseTexture = new BABYLON.Texture(ringCubMaterial, scene);
    ringMaterial.diffuseTexture.hasAlpha = false;
    ringMaterial.backFaceCulling = false;
    // faceUV
    const columns = 6; // 6 columns
    const rows = 1; // 1 row

    // alien sprite
    const faceUV = new Array(6);

    // set all faces to same
    for (let i = 0; i < 6; i += 1) {
      faceUV[i] = new BABYLON.Vector4(i / columns, 0, (i + 1) / columns, 1 / rows);
    }
    // 左侧两个高压柜
    const box1 = new BABYLON.MeshBuilder.CreateBox('box1', {
      height: 8,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box1.material = outterMaterial;
    box1.rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
    box1.position = new BABYLON.Vector3(-13.9, -1, -12);
    const box2 = new BABYLON.MeshBuilder.CreateBox('box2', {
      height: 8,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box2.material = outterMaterial;
    box2.rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
    box2.position = new BABYLON.Vector3(-13.9, -1, -5);
    // 变压器
    const box3 = new BABYLON.MeshBuilder.CreateBox('box3', {
      height: 8,
      width: 10,
      depth: 6,
      faceUV,
    }, scene);
    box3.material = transMaterial;
    box3.position = new BABYLON.Vector3(1, -1, 10);
    // 右侧 上方两个低压柜
    const box4 = new BABYLON.MeshBuilder.CreateBox('box4', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box4.material = outterMaterial;
    box4.position = new BABYLON.Vector3(18, -1, 10);
    const box5 = new BABYLON.MeshBuilder.CreateBox('box5', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box5.material = outterMaterial;
    box5.position = new BABYLON.Vector3(24, -1, 10);
    // 右侧 中间两个低压柜
    const box6 = new BABYLON.MeshBuilder.CreateBox('box6', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box6.material = outterMaterial;
    box6.rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
    box6.position = new BABYLON.Vector3(18, -1, -1);
    const box7 = new BABYLON.MeshBuilder.CreateBox('box7', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box7.material = outterMaterial;
    box7.rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
    box7.position = new BABYLON.Vector3(18, -1, -10);
    // 右侧 下方两个低压柜
    const box8 = new BABYLON.MeshBuilder.CreateBox('box8', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box8.material = outterMaterial;
    box8.position = new BABYLON.Vector3(18, -1, -18);
    const box9 = new BABYLON.MeshBuilder.CreateBox('box9', {
      height: 5,
      width: 4,
      depth: 4,
      faceUV,
    }, scene);
    box9.material = outterMaterial;
    box9.position = new BABYLON.Vector3(24, -1, -18);

    return scene;
  }
}
